.order-detail_title {
    font-family: var(--title-font);
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: var(--title-color);
    margin-bottom: 5px;
}

.order-detail_subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E7E7E7;
}

.order-detail_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #74757B;
    margin-bottom: 20px;
}