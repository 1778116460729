.taxi-box {
  position: relative;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
}

.taxi-box:hover {
  transform: scale(1.1);
}

/* .selected:before {
  content: "";
  z-index: -1;
  overflow: hidden;
  transform: rotate(-135deg);
  width: 120px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 34px;
  right: -16px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px, 110px 30px);
  height: 20px;
  width: 120px;
} */

.selected:after {
  content: "selected";
  z-index: 1;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  width: 120px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 20px;
  right: -30px;
  clip-path: polygon(
    120px 20px,
    90px -10px,
    30px -10px,
    0px 20px,
    10px 30px,
    110px 30px
  );
}

.text-link {
  text-decoration: underline;
  color: #1c84c6;
}

.modal-backdrop.fade.show {
  z-index: 1050;
}

.modal.fade.show {
  z-index: 1055;
}

.page {
  /* position: absolute; */
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 0;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.word-break {
  word-break: break-word;
}

.btn-red {
  color: #e30d16;
}

.facebook-mobile {
  display: inline-block;
  width: 100%;
  height: 300px;
  background-image: url("./assets/img/facebook-mobile.jpg");
  background-repeat: no-repeat;
  background-size: contain;
}

.facebook-mobile a {
  color: #fff;
  text-decoration: underline;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 10px;
  width: 100%;
}

.facebook-desktop {
  display: none;
}

@media (min-width: 768px) {
  .facebook-mobile {
    display: none;
  }

  .facebook-desktop {
    display: inline-block;
  }
}
