/* Table Styling */
.table-style {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Table Header */
.table-style thead th {
    background-color: #e6e6e6;
    color: #000;
    padding: 12px;
    text-align: left;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

/* Table Body */
.table-style tbody td {
    padding: 12px;
    border-bottom: 1px solid #ccc;
}

/* Zebra Striping */
.table-style tbody tr:nth-child(even) {
    background-color: #f0f0f0;
}

/* Hover Effect */
.table-style tbody tr:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

/* Table Footer */
.table-style tfoot td {
    background-color: #e6e6e6;
    color: #000;
    padding: 12px;
    font-size: 14px;
}

/* Responsive Table */
@media (max-width: 768px) {

    .table-style,
    .table-style thead,
    .table-style tbody,
    .table-style tfoot,
    .table-style th,
    .table-style td,
    .table-style tr {
        display: block;
        width: 100%;
    }

    .table-style tbody td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table-style tbody td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 45%;
        padding-left: 15px;
        text-align: left;
        font-weight: bold;
        color: #555;
    }
}