.tag {
    background-color: crimson;
    color: white;
    border-radius: 3px 0 0 3px;
    display: inline-block;
    height: 36px;
    line-height: 28px;
    padding: 5px 20px 5px 23px;
    position: absolute;
    margin: 0;
    text-decoration: none;
    -webkit-transition: color 0.2s;
    left: -10px;
    top: -10px;
}

.tag::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 15px;
}

.tag::after {
    background: #fff;
    border-bottom: 18px solid transparent;
    border-left: 15px solid crimson;
    border-top: 18px solid transparent;
    content: '';
    position: absolute;
    left: 99%;
    top: 0;
}